@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #07689f;
}

a:hover {
  color: #65c9cd;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #07689f;
  border-top-color: #ecf8f9;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #07689f;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #5ec6ca;
  color: #fff;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
#topbar {
  background: #07689f;
  color: #fff;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar i {
  padding-right: 6px;
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  top: 40px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  #header {
    padding: 15px 0;
  }
}

#header.header-scrolled {
  top: 0;
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

#header .logo a {
  color: #555555;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header {
    top: 0;
  }
  #header .logo {
    font-size: 28px;
  }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #555555;
  transition: 0.3s;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
}

/* .nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  color: #07689f;
} */

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 15px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #194b4d;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #07689f;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Appointment Button */
.appointment-btn {
  margin-left: 25px;
  background: #07689f;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}

.appointment-btn:hover {
  background: #65c9cd;
  color: #fff;
}

@media (max-width: 768px) {
  .appointment-btn {
    margin: 0 48px 0 0;
    padding: 6px 18px;
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 20px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #555555;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #555555;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #07689f;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(60, 60, 60, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
  # home Section
  --------------------------------------------------------------*/
#home {
  width: 100%;
  height: 100vh;
  background-color: rgba(60, 60, 60, 0.8);
  overflow: hidden;
  position: relative;
}

#home .carousel,
#home .carousel-inner,
#home .carousel-item,
#home .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#home .carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#home .container {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-top: 4px solid #07689f;
}

@media (max-width: 1200px) {
  #home .container {
    margin-left: 50px;
    margin-right: 50px;
  }
}

#home h2 {
  color: #2f2f2f;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
}

#home p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #555555;
}

#home .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#home .carousel-inner .carousel-item,
#home .carousel-inner .active.carousel-item-left,
#home .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#home .carousel-inner .active,
#home .carousel-inner .carousel-item-next.carousel-item-left,
#home .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#home .carousel-inner .carousel-item-next,
#home .carousel-inner .carousel-item-prev,
#home .carousel-inner .active.carousel-item-left,
#home .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#home .carousel-control-prev,
#home .carousel-control-next {
  width: 10%;
  top: 112px;
}

@media (max-width: 992px) {
  #home .carousel-control-prev,
  #home .carousel-control-next {
    top: 66px;
  }
}

#home .carousel-control-next-icon,
#home .carousel-control-prev-icon {
  background: none;
  font-size: 36px;
  line-height: 1;
  width: auto;
  height: auto;
  background: rgba(63, 187, 192, 0.4);
  border-radius: 50px;
  padding: 10px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
}

#home .carousel-control-next-icon:hover,
#home .carousel-control-prev-icon:hover {
  background: rgba(63, 187, 192, 0.6);
  color: rgba(255, 255, 255, 0.8);
}

#home .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#home .carousel-indicators li.active {
  opacity: 1;
  background: #07689f;
}

#home .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #07689f;
}

#home .btn-get-started:hover {
  background: #65c9cd;
}

@media (max-width: 992px) {
  #home {
    height: 100vh;
  }
  #home .container {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  #home h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  #home .carousel-control-prev,
  #home .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #home {
    height: 160vh;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 150px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f7fcfc;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #07689f;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #ecf8f9;
  min-height: 40px;
  margin-top: 120px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 70px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
  # Featured Services
  --------------------------------------------------------------*/
.featured-services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.featured-services .icon-box::before {
  content: "";
  position: absolute;
  background: #d9f1f2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.featured-services .icon-box:hover::before {
  background: #07689f;
  top: 0;
  border-radius: 0px;
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  font-size: 48px;
  line-height: 1;
  color: #07689f;
  transition: all 0.3s ease-in-out;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #111;
}

.featured-services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.featured-services .icon-box:hover .title a,
.featured-services .icon-box:hover .description {
  color: #fff;
}

.featured-services .icon-box:hover .icon i {
  color: #fff;
}

/*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
.cta {
  background: #07689f;
  color: #fff;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  font-size: 28px;
  font-weight: 700;
}

.cta .cta-btn {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #fff;
  color: #07689f;
}

/*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #07689f;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
  padding-bottom: 30px;
}

.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
}

.counts .count-box i {
  display: block;
  font-size: 30px;
  color: #07689f;
  float: left;
}

.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #555555;
  margin-left: 50px;
}

.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7b7b7b;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
  color: #07689f;
}

/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .icon-box i {
  font-size: 48px;
  float: left;
  color: #07689f;
}

.features .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.features .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: flex;
  justify-content: center;
}

.services .icon i {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: #07689f;
  font-size: 40px;
  overflow: hidden;
  padding-top: 20px;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}

.services .icon-box:hover .icon i {
  box-shadow: 0px 0 25px rgba(63, 187, 192, 0.3);
}

.services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.services .title a {
  color: #444444;
  transition: 0.3s;
}

.services .title a:hover {
  color: #07689f;
}

.services .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: #07689f;
  bottom: 0;
  left: calc(50% - 25px);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
  # Appointments
  --------------------------------------------------------------*/
.appointment .php-email-form {
  width: 100%;
}

.appointment .php-email-form .form-group {
  padding-bottom: 8px;
}

.appointment .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.appointment .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.appointment .php-email-form .error-message br + br {
  margin-top: 25px;
}

.appointment .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.appointment .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.appointment .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.appointment .php-email-form input,
.appointment .php-email-form textarea,
.appointment .php-email-form select {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 10px !important;
}

.appointment .php-email-form input:focus,
.appointment .php-email-form textarea:focus,
.appointment .php-email-form select:focus {
  border-color: #07689f;
}

.appointment .php-email-form input,
.appointment .php-email-form select {
  height: 44px;
}

.appointment .php-email-form textarea {
  padding: 10px 12px;
}

.appointment .php-email-form button[type="submit"] {
  background: #07689f;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.appointment .php-email-form button[type="submit"]:hover {
  background: #52c2c6;
}

/*--------------------------------------------------------------
  # Departments
  --------------------------------------------------------------*/
.departments .nav-tabs {
  border: 0;
}

.departments .nav-link {
  border: 0;
  padding: 20px;
  color: #555555;
  border-radius: 0;
  border-left: 5px solid #fff;
}

.departments .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}

.departments .nav-link p {
  font-size: 14px;
  margin-bottom: 0;
}

.departments .nav-link:hover h4 {
  color: #07689f;
}

.departments .nav-link.active {
  background: #f7fcfc;
  border-color: #07689f;
}

.departments .nav-link.active h4 {
  color: #07689f;
}

.departments .tab-pane.active {
  -webkit-animation: slide-down 0.5s ease-out;
  animation: slide-down 0.5s ease-out;
}

.departments .tab-pane img {
  float: left;
  max-width: 300px;
  padding: 0 15px 15px 0;
}

@media (max-width: 768px) {
  .departments .tab-pane img {
    float: none;
    padding: 0 0 15px 0;
    max-width: 100%;
  }
}

.departments .tab-pane h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #07689f;
}

.departments .tab-pane p {
  color: #777777;
}

.departments .tab-pane p:last-child {
  margin-bottom: 0;
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid #fff;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #b2e4e6;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: #f0fafa;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

.testimonials .owl-nav,
.testimonials .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.testimonials .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.testimonials .owl-dot.active {
  background-color: #07689f !important;
}

@media (max-width: 767px) {
  .testimonials {
    margin: 30px 10px;
  }
}

/*--------------------------------------------------------------
  # Doctors
  --------------------------------------------------------------*/
.doctors .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(63, 187, 192, 0.1);
}

.doctors .member .member-img {
  position: relative;
  overflow: hidden;
}

.doctors .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
}

.doctors .member .social a {
  transition: color 0.3s;
  color: #555555;
  margin: 0 10px;
  padding-top: 8px;
  display: inline-block;
}

.doctors .member .social a:hover {
  color: #07689f;
}

.doctors .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.doctors .member .member-info {
  padding: 25px 15px;
}

.doctors .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #555555;
}

.doctors .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.doctors .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.doctors .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
  # Gallery
  --------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .owl-nav,
.gallery .owl-dots {
  margin-top: 25px;
  text-align: center;
}

.gallery .owl-item {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.gallery .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0f4f5 !important;
}

.gallery .owl-dot.active {
  background-color: #07689f !important;
}

.gallery .gallery-carousel .owl-stage-outer {
  overflow: visible;
}

.gallery .gallery-carousel .center {
  border: 6px solid #07689f;
  margin: -10px;
  box-sizing: content-box;
  padding: 4px;
  background: #fff;
  z-index: 1;
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #07689f;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #07689f;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: #07689f;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #65c9cd;
}

.pricing .featured h3 {
  color: #fff;
  background: #07689f;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #07689f;
  color: #fff;
}

/*--------------------------------------------------------------
  # Frequently Asked Questioins
  --------------------------------------------------------------*/
.faq {
  padding: 150px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  padding: 0 0 20px 25px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: #07689f;
  font-size: 18px;
  font-weight: 500;
}

.faq .faq-list i {
  font-size: 18px;
  position: absolute;
  left: -25px;
  top: 6px;
}

.faq .faq-list p {
  margin-bottom: 20px;
  font-size: 15px;
}

.faq .faq-list a.collapse {
  color: #07689f;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #07689f;
}

.faq .faq-list a.collapsed i::before {
  content: "\eab2" !important;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #07689f;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #c5ebec;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #07689f;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #07689f;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #65c9cd;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  background: #eeeeee;
  padding: 0 0 30px 0;
  color: #555555;
  font-size: 14px;
}

#footer .footer-top {
  background: #f6f6f6;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #07689f;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #65c9cd;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #07689f;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #555555;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #07689f;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #07689f;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #65c9cd;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
}
